<template>
  <q-btn 
    v-if="tooltip_message" 
    flat round 
    :ripple="false" 
    color="dark" :icon="icon_name" 
    class="tooltip-flipnet"
    @touchstart.stop="tooltipEvents.handleTouchStart" 
    @mouseenter="tooltipEvents.handleMouseEnter"
    @mouseleave="tooltipEvents.handleMouseLeave" 
    ref="tooltipTarget"
  >
    <q-tooltip v-model="tooltipVisible" class="bg-dark" persistent :offset="[5, 5]" max-width="350px">
      <span v-html="$t(tooltip_message)" :style="isMobile ? 'font-size: 0.9rem' : 'font-size: 0.8rem !important'" />
    </q-tooltip>
  </q-btn>
</template>
<script setup>
import { useQuasar } from "quasar";
import { computed, onBeforeUnmount, onMounted, ref, defineProps } from "vue";

defineProps({
  tooltip_message: {
    type: String,
    required: true,
  },
  icon_name: {
    type: String,
    default: 'help'
  }
});

const $q = useQuasar();

const isMobile = computed(() => {
  return $q.screen.lt.md;
});

const tooltipTarget = ref(null);

const tooltipVisible = ref(false);

// Funções de eventos do tooltip
const tooltipEvents = {
  handleTouchStart(event) {
    if (isMobile.value) {
      event.stopImmediatePropagation();
      tooltipVisible.value = !tooltipVisible.value;
    }
  },
  handleMouseEnter() {
    if (!isMobile.value) {
      tooltipVisible.value = true;
    }
  },
  handleMouseLeave() {
    if (!isMobile.value) {
      tooltipVisible.value = false;
    }
  },
  handleClickOutside(event) { // Clicar em qualquer lugar da tela ( exceto no próprio tooltip ), fecha o tooltip
    const tooltipElement = tooltipTarget.value?.$el || tooltipTarget.value;

    if (
      tooltipElement instanceof HTMLElement &&
      !tooltipElement.contains(event.target) &&
      !event.target.closest(".q-tooltip")
    ) {
      tooltipVisible.value = false;
    }
  }
}

onMounted(() => {
  document.addEventListener("click", tooltipEvents.handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", tooltipEvents.handleClickOutside);
});
</script>
